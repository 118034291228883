<template>
  <div id="receipt">
    <div

      class="receipt-body"
    >
      <!--      <div-->
      <!--        class="school-logo"-->
      <!--        style="-->
      <!--        position: absolute;-->
      <!--        top: 10px;-->
      <!--        right: 0;-->
      <!--        margin-left: auto;-->
      <!--        margin-right: auto;-->
      <!--        left: 10px;-->
      <!--        padding: 5px;-->
      <!--        background-color: white;-->
      <!--        border-radius: 40px;-->
      <!--        height: 40px;-->
      <!--        width: 40px;-->
      <!--"-->
      <!--      >-->
      <!--        <img-->
      <!--          width="70"-->
      <!--          height="70"-->
      <!--          style="border-radius: 70px"-->
      <!--          :src="schoolLogo?`${schoolLogo}`:`https://thumbs.dreamstime.com/b/beautiful-green-spa-logo-beauty-green-spa-logo-woman-inside-white-background-183476456.jpg`"-->
      <!--          alt=""-->
      <!--        >-->
      <!--      </div>-->
      <div
        class="school-name"
        style="      position: absolute;
        top: 90px;
        text-align: center;
        color: black;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        font-size: 22px;
        text-transform: uppercase;"
      >
        <strong>{{ schoolName }}</strong>
        <p
          style=" margin: 0px !important;
        padding: 5px !important;
        font-size: 19px !important;
        font-weight: bolder;
        text-decoration: underline;"
        >{{ receipt.title }}</p>
      </div>
      <div
        class="receipt"
        style="   position: absolute;
        top: 150px;
        left: 10px;
        width: 680px;"
      >
        <table style="width: 680px; border-spacing: 2px 5px;">
          <tr>
            <td>
              <div style="border-bottom: 2px dotted black">
                FULL NAME : <strong> {{ receipt.last_name }} {{ receipt.first_name }} {{ receipt.middle_name }}</strong>
              </div>
            </td>
            <td>

              <div style="border-bottom: 2px dotted black">
                RECEIPT ID : <strong> {{ receipt.receipt_id }}</strong>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="border-bottom: 2px dotted black;">
                PAID ({{ currencySymbol }}) : <strong>{{ receipt.amount_paid | currency }} <span style="font-style: italic;">( {{ receipt.is_full_payment?'full payment':'part payment' }})</span></strong>
              </div>
            </td>
            <td>
              <div style="border-bottom: 2px dotted black;">
                ARREARS ({{ currencySymbol }}) : <strong> {{ receipt.pending_amount | currency }}</strong>
              </div>
            </td>

          </tr>
          <tr>
            <td>
              <div style="border-bottom: 2px dotted black;">
                PAYER : <strong> {{ receipt.payer_name }}</strong>
              </div>
            </td>
            <td>
              <div style="border-bottom: 2px dotted black;">
                PAYER PHONE : <strong> {{ receipt.payer_phone }}</strong>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="border-bottom: 2px dotted black;">
                PAYMENT CHANNEL : <strong> {{ receipt.payment_mode }}</strong>
              </div>
            </td>
            <td>
              <div style="border-bottom: 2px dotted black;">
                PAYMENT DATE : <strong> {{ receipt.fee_payment_date }}</strong>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div
        class="receipt-date"
        style="    color: black;
        position: absolute;
        bottom: 20px;
        left: 20px;
        font-style: italic;"
      >
        <strong>Receipt Date : {{ today }}</strong>
      </div>
      <div
        style="position: absolute;
        bottom: 10px;
        right: 10px;"
      >
        <img
          id="qrcode"
          style="width: 90px; height: 60px"
        >
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import QRCode from 'qrcode'
import eventBus from '@/lenon/utils/eventBus'
import showToast from '@/lenon/mixins/showToast'

export default {
  name: 'Receipt',
  mixins: [showToast],
  data() {
    return {
      today: null,
      receipt: {},
    }
  },
  computed: {
    currencySymbol() {
      return this.$store.getters['auth/currency']
    },
    schoolLogo() {
      return this.$store.getters['auth/schoolLogo']
    },
    schoolName() {
      return this.$store.getters['auth/schoolName']
    },
    schoolCode() {
      return this.$store.getters['auth/schoolCode']
    },
  },
  watch: {
    // receipt(r) {
    //
    //   // JsBarcode('#barcode', `${this.schoolCode}.${r.receipt_id}`, {
    //   //   width: 0.5,
    //   //   height: 35,
    //   //   displayValue: false,
    //   //   background: ' rgba(255, 255, 255, 0);',
    //   //   lineColor: '#000',
    //   // })
    // },
  },
  beforeDestroy() {
    eventBus.$off(['printReceipt'])
  },
  mounted() {
    eventBus.$on('printReceipt', receipt => {
      this.receipt = receipt
      this.printDocument()
    })
  },
  methods: {
    printDocument() {
      if (this.receipt.code) {
        this.today = moment().format('ll')
        const opts = {
          errorCorrectionLevel: 'H',
          type: 'image/jpeg',
          quality: 0.7,
          margin: 1,
          color: {
            dark: '#000000',
            light: '#FFFFFF',
          },
        }

        QRCode.toDataURL(this.receipt.code, opts, (err, url) => {
          if (err) throw err
          const qrcode = document.getElementById('qrcode')
          qrcode.src = url
        })
        setTimeout(async () => {
          await this.$htmlToPaper('receipt')
        }, 100)
      } else {
        this.showInfo('You can not print this receipt, make sure its a valid receipt')
      }
    },
  },
}
</script>

<style>
  .page-break {
    page-break-after: always;
  }
  #qrcode {
    /* height: 60px; */
    /* background-color: black; */
    position: absolute;
    bottom: 0px;
    right: 10px;
  }

  .school-logo {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    /* top: 10px; */
    /* padding: 5px; */
    /* background-color: white; */
  }
  .school-name {
    position: absolute;
    top: 10px;
    text-align: center;
    color: black;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    text-transform: uppercase;
  }
  .school-name p {
    margin: 0px !important;
    padding: 5px !important;
    font-size: 14px !important;
    font-weight: bolder;
    text-decoration: underline;
  }
  .school-logo {
    position: absolute;
    left: 10px;
    /* right: 0;
    margin-left: auto;
    margin-right: auto; */
    top: 10px;
    padding: 5px;
    background-color: white;
    border-radius: 40px;
    height: 40px;
    width: 40px;
  }
  .receipt-body {
    width: 700px;
    height: 250px;
    border: 2px black dashed;
    position: relative;
    margin-bottom: 30px;
  }
  .receipt {
    position: absolute;
    top: 60px;
    left: 10px;
    width: 680px;
  }
  .receipt-date {
    /* height: 60px; */
    /* background-color: black; */
    color: black;
    position: absolute;
    bottom: 10px;
    left: 20px;
    font-style: italic;
  }
  table {
    border-spacing: 2px 5px;

  }
</style>
