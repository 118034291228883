<template>
  <div>
    <!--    FeeSetting Form-->
    <validation-observer
      ref="feePaymentForm"
      v-slot="{invalid}"
    >
      <lenon-modal
        :title="`Record Payments - ${fullName}`"
        :show="feePaymentModalOpened"
        :show-overlay="loadingPendingFees"
        size="md"
        @onClose="closeFeeSettingModal()"
      >
        <error-display :error="error" />
        <div
          v-if="madeFullPayment"
          class="text-center"
        >
          <b-badge variant="light-success">
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-25"
            />
            <span>Student has made full payment for selected fee</span>
          </b-badge>
        </div>
        <lenon-input
          v-model="searchText"
          name="search"
          placeholder="Enter Name(Last + First Name) or ID then press Enter"
          prefix-icon="SearchIcon"
          :suffix-icon="null"
          @onEnterPressed="handleSearch"
        />
        <div class="mt-2 d-flex justify-content-center">
          <lenon-profile-photo
            :photo="student.photo"
            size="100"
          />
        </div>
        <lenon-select
          v-model="feePayment.fee_setting_id"
          label-name="display_name"
          value-name="id"
          label="Fee Type"
          placeholder="Select Fee Type"
          :options="feeSettings"
          rules="required"
        />

        <div
          v-if="pendingFee"
          class="mt-2 mb-2"
        >
          <div class="row">
            <div class="col-4">
              Total: <strong>{{ pendingFee.total_amount }}</strong>
            </div>
            <div class="col-4">
              Paid: <strong>{{ pendingFee.paid_amount }}</strong>
            </div>
            <div class="col-4">
              Pending: <strong>{{ pendingFee.pending_amount }}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              Due: <strong>{{ pendingFee.due_date }}</strong>
            </div>
          </div>
        </div>
        <lenon-input
          v-model="feePayment.amount"
          name="amount"
          placeholder="Enter Amount"
          :rules="`required|numeric|max_value:${pendingAmount}|min_value:1`"
          label="Amount"
        />
        <lenon-date-picker
          v-model="feePayment.date"
          name="date"
          placeholder="Select Date"
          label="Select Date"
          rules="required|no_future_date"
          :enable-time="true"
        />
        <lenon-input
          v-model="feePayment.payer_name"
          name="payer_name"
          placeholder="Enter Payer Name"
          label="Payer Name"
        />
        <lenon-input
          v-model="feePayment.payer_phone"
          name="payer_phone"
          placeholder="Enter Payer Phone"
          label="Payer Phone"
        />
        <b-row
          v-if="hasReceipt"
          class="float-right"
        >
          <div class="col-12">
            <lenon-button
              label="Generate Receipt for Last Payment"
              icon="PrinterIcon"
              variant="outline-primary"
              :loading="generatingReceipts"
              @onClick="printReceipt()"
            />
          </div>
        </b-row>
        <template slot="modal-actions">
          <b-row class="float-right">
            <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeFeeSettingModal()"
            />
            <lenon-button
              label="Record Payment"
              :disabled="invalid"
              :loading="feePaymentLoading"
              loading-text="Loading..."
              @onClick="recordFeePayment()"
            />
          </b-row>
        </template>
      </lenon-modal>
    </validation-observer>
    <!--    <receipt v-show="false" />-->
  </div>
</template>

<script>

import { BRow, BBadge, BAvatar } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import {
  RECORD_FEE_PAYMENT_M,
} from '@/graphql/mutations'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { FETCH_STUDENT_FOR_FEE_PAYMENT_Q, LOAD_STUDENT_PENDING_FEE_Q } from '@/graphql/queries'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import logData from '@/libs/log'
import Receipt from '@/views/finance/fee-payments/Receipt.vue'
import eventBus from '@/lenon/utils/eventBus'
import receipt from '@/lenon/mixins/receipt'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto'

export default {
  name: 'FeePayment',
  components: {
    LenonProfilePhoto,
    ErrorDisplay,
    Receipt,
    LenonDatePicker,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BBadge,
    BAvatar,
  },
  mixins: [showToast, receipt],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    classId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      error: {},
      searchText: null,
      loadingPendingFees: false,
      feePaymentModalOpened: false,
      feePaymentLoading: false,
      feePayment: {
        fee_setting_id: null,
        amount: null,
        date: null,
        payer_name: null,
        payer_phone: null,
      },
      pendingFee: null,
    }
  },
  computed: {
    madeFullPayment() {
      return this.pendingFee?.pending_amount === 0
    },
    pendingAmount() {
      return this.pendingFee?.pending_amount || 0
    },
    student() {
      return this.$store.getters['feePayments/student']
    },
    previousStudentId() {
      return this.$store.getters['feePayments/previousStudentId']
    },
    fullName() {
      if (this.student) {
        return `${this.student.last_name} ${this.student.first_name} ${this.student.middle_name || ''}`
      }
      return ''
    },
    feeSettings() {
      const settings = this.$store.getters['feeSettings/feeSettings']
      return settings.filter(s => s.classes.includes(this.studentClassId))
    },
    feeSettingId() {
      return this.feePayment.fee_setting_id
    },
    hasReceipt() {
      return !!this.student.receipt_id
    },
    studentClassId() {
      return this.student.class_id
    },
  },
  watch: {
    modalOpened(opened) {
      this.feePaymentModalOpened = opened
      if (opened && this.previousStudentId !== this.student.student_id) {
        this.resetFeePaymentForm()
        this.$store.commit('feePayments/updateStudentId')
      }
    },
    feeSettingId(id) {
      if (id) {
        this.loadStudentPendingFees(id)
      } else {
        this.pendingFee = null
      }
    },
  },
  methods: {
    printReceipt() {
      this.generateReceipt({ fee_ids: [this.student.fee_id], fee_setting_id: this.student.fee_setting_id })
      // eventBus.$emit('printReceipt', this.student)
    },
    resetFeePaymentForm() {
      this.pendingFee = null
      this.feePayment = {
        fee_setting_id: null,
        amount: null,
        date: null,
        payer_name: null,
        payer_phone: null,
      }
      this.$refs.feePaymentForm.reset()
    },
    handleSearch(search) {
      this.fetchStudentForFeePayment(search)
    },
    loadStudentPendingFees(feeSettingId) {
      this.error = {}
      if (this.student) {
        this.loadingPendingFees = true
        this.$apollo.query({
          query: LOAD_STUDENT_PENDING_FEE_Q,
          variables: { student_id: this.student.student_id, fee_setting_id: feeSettingId },
        }).then(res => {
          logData(res)
          if (res.errors) {
            this.feePayment.fee_setting_id = null
            this.error = { graphQLErrors: res.errors }
          }
          this.loadingPendingFees = false
          this.pendingFee = res.data.studentPendingFee
        }).catch(err => {
          this.error = err
          logData(err)
          this.feePayment.fee_setting_id = null
          this.loadingPendingFees = false
          this.showError('Failed to load pending fee')
        })
      }
    },
    closeFeeSettingModal() {
      this.feePaymentModalOpened = false
      this.$emit('modalClosed')
    },
    fetchStudentForFeePayment(search) {
      this.error = {}
      this.loadingPendingFees = true
      this.$apollo.query({
        query: FETCH_STUDENT_FOR_FEE_PAYMENT_Q,
        variables: { search },
      })
        .then(res => {
          this.loadingPendingFees = false
          if (res.data && res.data.studentToRecordFeesFor) {
            this.$store.commit('feePayments/setStudentToRecordFeesFor', res.data.studentToRecordFeesFor)
          } else {
            this.searchText = null
            this.showInfo('Student not found')
          }
          if (this.feeSettingId) {
            this.loadStudentPendingFees(this.feeSettingId)
          }
        }).catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to load student')
          this.loadingPendingFees = false
        })
    },
    recordFeePayment() {
      this.error = {}
      this.feePaymentLoading = true
      this.$apollo.mutate({
        mutation: RECORD_FEE_PAYMENT_M,
        variables: {
          input: {
            ...this.feePayment,
            student_id: this.student.student_id,
            pending_amount: +this.pendingFee.pending_amount,
            amount: +this.feePayment.amount,
            payer_name: this.feePayment.payer_name || 'N/A',
            payer_phone: this.feePayment.payer_phone || 'N/A',
          },
        },
      })
        .then(res => {
          this.showSuccess('Recorded fee payment successfully')
          this.feePaymentLoading = false
          this.$store.commit('feePayments/updateStudentsForFeePayment', res.data.recordFeePayment)
          this.resetFeePaymentForm()
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to record fee payment')
          this.feePaymentLoading = false
        })
    },
  },
}
</script>
