<template>
  <div class="academic-years">
    <receipt v-show="false" />
    <lenon-list-page
      title="Fee Payments"
      :columns="columns"
      :rows="studentsForFeePayment"
      :table-loading="tableLoading || generatingReceipts"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      :enable-multi-select="true"
      search-placeholder="Search"
      :show-profile-photo="true"
      @onRowSelect="handleRowSelect"
    >
      <template slot="table-header">.
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="col-md-12"
        >
          <lenon-select
            v-model="selectedClass"
            placeholder="Select a Class"
            :options="classes"
            label-name="name"
            value-name="id"
          />
        </div>
        <div class="d-flex flex-row full-width mb-1 ml-1 text-danger text-italic">
          <strong>
            NB: This table displays a list of students in the selected class for fee collection. In addition,
            it also shows details of the last payment made by each student with an option to quickly generate a receipt.
            Click on the button in the "ACTION" column to start recording fee payments.
          </strong>

        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px;"
        >
          <lenon-select
            v-model="selectedClass"
            placeholder="Select a Class"
            :options="classes"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-button
            icon="PlusIcon"
            label="Include"
            variant="outline-primary"
            tool-tip-text="Include students to specific fees"
            class="mr-1"
            @onClick="showModal(true)"
          />
          <lenon-button
            icon="MinusIcon"
            label="Exclude"
            variant="primary"
            tool-tip-text="Exclude students from specific fees"
            @onClick="showModal(false)"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-dropdown left>
            <lenon-dropdown-item
              icon="PlusIcon"
              text="Include Students"
              @click="showModal(true)"
            />
            <lenon-dropdown-item
              icon="MinusIcon"
              text="Exclude Students"
              @click="showModal(false)"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #full_name="{row}">
        {{ row.item.last_name }} {{ row.item.first_name }} {{ row.item.middle_name || '' }}
      </template>
      <template
        #amount_paid="{row}"
      >
        <div v-if="row.item.amount_paid>0">
          {{ row.item.amount_paid | currency }}
        </div>
        <div v-else>
          {{ '---' }}
        </div>
      </template>
      <template
        #last_payment_class="{row}"
      >
        <div v-if="row.item.last_payment_class">
          {{ row.item.last_payment_class }}
        </div>
        <div v-else>
          {{ '---' }}
        </div>
      </template>
      <template #payer_name="{row}">
        {{ row.item.payer_name ? row.item.payer_name : '---' }}
      </template>
      <template #payer_phone="{row}">
        {{ row.item.payer_phone ? row.item.payer_phone : '---' }}
      </template>
      <template #fee_payment_date="{row}">
        {{ row.item.fee_payment_date ? row.item.fee_payment_date : '---' }}
      </template>
      <template #title="{row}">
        {{ row.item.title ? row.item.title : '---' }}
      </template>
      <template #actions="{row}">
        <lenon-button
          :icon-only="true"
          variant="flat-primary"
          :tool-tip-text="`Record Payment for ${row.item.first_name} ${row.item.last_name}`"
          icon="EditIcon"
          @onClick="handlePayment(row.item)"
        />
        <lenon-button
          v-if="row.item.receipt_id"
          :icon-only="true"
          variant="flat-success"
          tool-tip-text="Generate Receipt for Last Payment"
          icon="PrinterIcon"
          @onClick="printReceipt(row.item)"
        />

        <!--                <lenon-dropdown>-->
        <!--                    <b-dropdown-item-->
        <!--                            v-if="row.item.receipt_id"-->
        <!--                            @click="printReceipt(row.item)"-->
        <!--                    >-->
        <!--                        <feather-icon-->
        <!--                                icon="PrinterIcon"-->
        <!--                                class="mr-50"-->
        <!--                        />-->
        <!--                        <span> Receipt</span>-->
        <!--                    </b-dropdown-item>-->
        <!--                    <b-dropdown-item-->
        <!--                            @click="handlePayment(row.item)"-->
        <!--                    >-->
        <!--                        <feather-icon-->
        <!--                                icon="PlusIcon"-->
        <!--                                class="mr-50"-->
        <!--                        />-->
        <!--                        <span> Payment</span>-->
        <!--                    </b-dropdown-item>-->
        <!--                </lenon-dropdown>-->
      </template>
    </lenon-list-page>
    <include-exclude
      :modal-opened="includeExcludeModalOpened"
      :include-mode="includeMode"
      :students="selectedStudents"
      :class-id="selectedClass"
      @modalClosed="closeModal('includeExclude')"
    />
    <fee-payment
      :class-id="selectedClass"
      :modal-opened="feePaymentModalOpened"
      @modalClosed="closeModal('feePayment')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { FETCH_STUDENTS_FOR_FEE_PAYMENTS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import { BDropdownItem } from 'bootstrap-vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import IncludeExclude from '@/views/finance/fee-payments/IncludeExclude.vue'
import FeePayment from '@/views/finance/fee-payments/FeePayment.vue'
import logData from '@/libs/log'
import Receipt from '@/views/finance/fee-payments/Receipt.vue'
import receipt from '@/lenon/mixins/receipt'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'

export default {
  name: 'FeePayments',
  components: {
    LenonDropdownItem,
    Receipt,
    FeePayment,
    IncludeExclude,
    LenonSelect,
    LenonDropdown,
    LenonListPage,
    LenonButton,
    BDropdownItem,
  },
  mixins: [showToast, receipt],
  data() {
    return {
      initialLoad: false,
      includeExcludeModalOpened: false,
      feePaymentModalOpened: false,
      tableLoading: false,
      selectedClass: null,
      selectedReceipt: {},
      columns: [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'full_name',
          label: 'Student Full Name',
        },
        {
          key: 'fee_payment_date',
          label: 'Last Payment Date',
        },
        {
          key: 'last_payment_class',
          label: 'Last Payment Class',
        },
        {
          key: 'title',
          label: 'Last Fee Type',
        },
        {
          key: 'amount_paid',
          label: `Last Payment Amount(${this.$store.getters['auth/currency']})`,
        },
        // {
        //   key: 'payer_name',
        //   label: 'Payer Name',
        // },
        // {
        //   key: 'payer_phone',
        //   label: 'Payer Phone',
        // },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      includeMode: false,
      selectedStudents: [],
    }
  },
  computed: {
    studentsForFeePayment() {
      return this.$store.getters['feePayments/studentsForFeePayment']
    },
    classes() {
      return this.$store.getters['termsClasses/termClasses']
    },
  },
  watch: {
    selectedClass(cls) {
      if (cls !== null) {
        this.$store.commit('feePayments/setSelectedClass', cls)
        this.fetchStudentsForFeePayments(cls)
      } else {
        this.$store.commit('feePayments/setStudentsForFeePayment', { studentsForFeePayment: [] })
      }
    },
  },
  mounted() {
    this.initialLoad = true
    this.selectedClass = this.$store.getters['feePayments/selectedClass']
    if (!this.selectedClass) {
      this.$store.commit('feePayments/setStudentsForFeePayment', { studentsForFeePayment: [] })
    }
  },
  methods: {
    printReceipt(rc) {
      this.generateReceipt({
        fee_ids: [rc.fee_id],
        fee_setting_id: rc.fee_setting_id,
      })
    },
    handlePayment(student) {
      this.$store.commit('feePayments/setStudentToRecordFeesFor', student)
      this.feePaymentModalOpened = true
    },
    showModal(include) {
      if (this.selectedStudents.length > 0) {
        this.includeExcludeModalOpened = true
        this.includeMode = include
      } else {
        this.showInfo('Please select at least one student')
      }
    },
    handleRowSelect(students) {
      this.selectedStudents = students.map(s => +s.student_id)
    },
    getStatusText(status) {
      let text = 'Pending'
      // eslint-disable-next-line default-case
      switch (+status) {
        case 1:
          text = 'In Progress'
          break
        case 0:
          text = 'Due'
          break
      }
      return text
    },
    getStatusVariant(status) {
      let variant = 'light-warning'
      // eslint-disable-next-line default-case
      switch (+status) {
        case 1:
          variant = 'light-success'
          break
        case 0:
          variant = 'light-danger'
          break
      }
      return variant
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'includeExclude':
          this.includeExcludeModalOpened = false
          break
        case 'feePayment':
          this.feePaymentModalOpened = false
          break
      }
    },
    showFeeSettingModal() {
      this.includeExcludeModalOpened = true
    },

    fetchStudentsForFeePayments(selectedClass) {
      this.tableLoading = (this.initialLoad ? this.studentsForFeePayment.length < 1 : true)
      this.initialLoad = false
      this.$apollo.query({
        query: FETCH_STUDENTS_FOR_FEE_PAYMENTS_Q,
        variables: { class_id: selectedClass },
      })
        .then(res => {
          this.tableLoading = false
          this.$store.commit('feePayments/setStudentsForFeePayment', res.data)
        })
        .catch(err => {
          logData(err)
          this.showError('Failed to load students for fee payment')
        })
    },
  },
}
</script>
