<template>
  <!--    FeeSetting Form-->
  <validation-observer
    ref="includeExcludeForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${includeMode?'Include':'Exclude'} Students`"
      :show="includeExcludeModalOpened"
      @onClose="closeIncludeExcludeModal()"
    >
      <div class="" />
      <lenon-multi-select
        v-model="selectedFeeSettings"
        value-name="id"
        label-name="display_name"
        name="fees"
        placeholder="Select Fees"
        :options="feeSettings"
        rules="required"
        :label="`Select Fees to ${includeMode?'Include':'Exclude'}`"
      />
      <div class="mb-5" />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeIncludeExcludeModal()"
          />
          <lenon-button
            label="Save"
            :disabled="invalid"
            :loading="saving"
            loading-text="Loading..."
            @onClick="submit()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import { BRow } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import {
  INCLUDE_EXCLUDE_STUDENTS_M,
} from '@/graphql/mutations'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'

export default {
  name: 'IncludeExclude',
  components: {
    LenonMultiSelect,
    LenonButton,
    LenonModal,
    ValidationObserver,
    BRow,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    includeMode: {
      type: Boolean,
      default: false,
    },
    classId: {
      type: [Number, String],
      default: null,
    },
    students: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      saving: false,
      includeExcludeModalOpened: false,
      selectedFeeSettings: [],
    }
  },
  computed: {
    feeSettings() {
      const settings = this.$store.getters['feeSettings/feeSettings']
      return settings.filter(s => s.classes.includes(this.classId))
    },
  },
  watch: {
    modalOpened(opened) {
      this.includeExcludeModalOpened = opened
    },
  },
  methods: {
    resetFeeSettingForm() {
      this.feeSettings = []
      this.$refs.includeExcludeForm.reset()
    },
    closeIncludeExcludeModal() {
      this.includeExcludeModalOpened = false
      this.$emit('modalClosed')
    },
    submit() {
      this.saving = true
      this.$apollo.mutate({
        mutation: INCLUDE_EXCLUDE_STUDENTS_M,
        variables: {
          input: {
            fee_setting_ids: this.selectedFeeSettings.map(s => +s.id),
            student_ids: this.students,
            class_id: this.classId,
            include: this.includeMode,
          },
        },
      })
        .then(res => {
          this.showSuccess(`${this.includeMode ? 'Included students to selected fees' : 'Excluded students from selected fees'}`)
          this.saving = false
          this.resetFeeSettingForm()
        })
        .catch(err => {
          console.log(err)
          this.showError('Something went wrong, please try again')
          this.saving = false
        })
    },
  },
}
</script>
